<template>
  <div
    class="weights-edit__root"
  >
    <div class="weights-edit__section">
      <div class="weights-edit--section__header">
        <Heading
          as="h2"
          size="xs"
        >
          {{ title }}
        </Heading>
        <div class="section-subtitle">
          Configure weight by selecting bookmakers and defining value
        </div>
        <div class="section-search-content">
          <div class="search-container">
            <TextInput
              v-model="search"
              placeholder="Search bookmakers"
              icon="search"
              small
            />
            <div class="weights-edit-buttons-toggle">
              <Toggle
                :model-value="showActiveOnly"
                :disabled="showActiveDisabled"
                @update:model-value="toggleShowActiveOnly"
              />
              <span>Show active only</span>
            </div>
          </div>
        </div>
        <div
          :class="['section-error-content', { 'active': weightsSum > 1 }]"
        >
          <Icon :name="'alert-cycle'" />
          <span class="error-message">Weights sum should be equal to 1</span>
        </div>
      </div>
      <div
        v-if="filteredBookmakers.length"
        class="weights-edit-configuration__content"
      >
        <div
          v-for="bookmaker in filteredBookmakers"
          :key="bookmaker.key"
          class="weights-edit-configuration-row"
        >
          <div class="weights-edit-configuration-row__bookmaker">
            <Toggle
              :model-value="bookmaker.active"
              :disabled="weightsUpdateInProgress"
              @update:model-value="toggleActive(bookmaker)"
            />
            <div class="bookmaker-logo-container">
              <img
                :src="getBookmakerLogo(bookmaker.key)"
                :alt="bookmaker.key"
              >
            </div>
            <span>{{ bookmaker.name }}</span>
          </div>
          <div class="weights-edit-configuration-row__weight">
            <div :class="['weights-input', {'readonly':!bookmaker.active}]">
              <div
                class="increase-decrease-button left"
                @click="updateWeight(bookmaker, '-')"
              >
                <Icon :name="'minus'" />
              </div>
              <NumberInput
                :model-value="bookmaker.weight"
                :readonly="!bookmaker.active || weightsUpdateInProgress"
                :max="1"
                :is-error="bookmaker.active && (!bookmaker.weight || bookmaker.weight < 0)"
                :error-message="'Weight should be greater than 0'"
                :parent-selector="'.weights-edit__root'"
                @update:modelValue="value => updateWeightValue(bookmaker, value)"
              />
              <div
                class="increase-decrease-button right"
                @click="updateWeight(bookmaker, '+')"
              >
                <Icon :name="'plus'" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="weights-edit-configuration__empty-results"
      >
        <span>No results found</span>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import {
  filter, includes, toLower,
  every,
} from 'lodash';
import Heading from '@/components/common/Heading';
import TextInput from '@/components/common/TextInput';
import Toggle from '@/components/common/Toggle';
import Icon from '@/components/common/Icon';
import NumberInput from '@/components/common/NumberInput';
import { calculateAfterOperationValue } from '@/services/helpers/market-tiers';
import { findLogoByName } from '@/services/helpers/logos';

export default {
  components: {
    Heading,
    TextInput,
    Toggle,
    Icon,
    NumberInput,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    bookmakers: {
      type: Array,
      default: () => [],
    },
    weightsUpdateInProgress: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'update:bookmaker': {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const search = ref('');
    const showActiveOnly = ref(false);
    const showActiveDisabled = computed(() => every(props.bookmakers, (bookmaker) => !bookmaker.active));
    const filteredBookmakers = computed(() => filter(
      props.bookmakers, (bookmaker) => {
        if (showActiveOnly.value && !bookmaker.active) return false;
        return includes(toLower(bookmaker.name), toLower(search.value));
      },
    ));
    const weightsSum = computed(() => props.bookmakers.reduce((acc, bookmaker) => {
      if (!bookmaker.active) return acc;
      return Number(Number(acc + bookmaker.weight).toFixed(3));
    }, 0));
    const toggleShowActiveOnly = (value) => {
      showActiveOnly.value = value;
    };
    const updateWeightValue = (bookmaker, value) => {
      if (!bookmaker.active || props.weightsUpdateInProgress) return;
      const updatedBookmaker = { ...bookmaker, weight: value ? parseFloat(value) : value };
      emit('update:bookmaker', updatedBookmaker);
    };

    const updateWeight = (bookmaker, action) => {
      if (!bookmaker.active || props.weightsUpdateInProgress) return;
      if (action === '+' && weightsSum.value >= 1) return;
      const updatedBookmaker = { ...bookmaker, weight: calculateAfterOperationValue(action, bookmaker.weight, 0, 1, 0.01) };
      emit('update:bookmaker', updatedBookmaker);
    };

    const toggleActive = (bookmaker) => {
      const updatedBookmaker = { ...bookmaker, active: !bookmaker.active };
      emit('update:bookmaker', updatedBookmaker);
    };

    const getBookmakerLogo = (key) => findLogoByName(key);

    return {
      search,
      toggleShowActiveOnly,
      updateWeightValue,
      updateWeight,
      toggleActive,
      getBookmakerLogo,
      showActiveOnly,
      showActiveDisabled,
      filteredBookmakers,
      weightsSum,
    };
  },
};
</script>
<style lang="scss">
.weights-edit__root {
  flex: 1;
  display: block;
  height: 100%;
  .weights-edit__section {
    background-color: #fff;
    padding: 16px;
    border: 1px solid #DDD;
    border-radius: 4px;
    min-height: 325px;
    height: 100%;
    width: 100%;
    overflow: hidden;

    .weights-edit--section__header {
      padding-bottom: 4px;
    }

    .section-subtitle {
        font-size: 12px;
        color: $gray700;
    }

    .section-search-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;
        .search-container {
            display: flex;
            align-items: center;
            gap: 24px;
            .weights-edit-buttons-toggle {
                display: flex;
                align-items: center;
                gap: 8px;
            }
        }
    }

    .section-error-content {
        display: flex;
        justify-content: left;
        align-items: center;
        margin-top: 6px;
        gap: 8px;
        color: $error500;
        opacity: 0;
        &.active {
            opacity: 1;
        }
        svg {
          path {
            stroke: red;
          }
      }
    }

    .weights-edit-configuration {
        &__content {
            height: calc(100% - 120px);
            overflow-y: auto;
            .weights-edit-configuration-row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 8px 0;
                border-bottom: 1px solid #F0F0F0;

                &__bookmaker {
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    .bookmaker-logo-container {
                      width: 42px;
                      height: auto;
                      object-fit: contain;
                      border-radius: 4px;
                      overflow: hidden;
                      img {
                        width: 100%;
                      }
                    }
                }

                &__weight {
                    .weights-input {
                        display: flex;

                        .increase-decrease-button {
                            $button-size: 33px;
                            height: $button-size;
                            width: $button-size;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border: 1px solid #DDDDDD;
                            cursor: pointer;

                            &.left {
                                border-radius: 4px 0 0 4px;
                            }

                            &.right {
                                border-radius: 0 4px 4px 0;
                            }

                            svg path {
                                stroke: #191414;
                            }
                        }

                        .table-number-input {
                            width: 74px;

                            input {
                                text-align: center;
                                border-color: #DDDDDD;
                                height: 33px;
                                border-width: 1px 0;
                                border-style: solid;
                                border-radius: 0;

                                &:read-only {
                                    background-color: $gray400;
                                    color: $gray700;
                                }
                            }
                        }

                        .us-cent-dd {
                            width: 120px;
                        }
                    }
                }
            }
        }
        &__empty-results {
            display: flex;
            justify-content: center;
            margin-top: 80px;
        }
    }
  }
}
</style>

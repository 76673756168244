<template>
  <div class="weights-configuration">
    <WeightsSidebar />
    <div class="weights-configuration__main">
      <div class="weights-configuration__header">
        <div class="weights-configuration__text">
          <Heading>{{ title }}</Heading>
        </div>

        <Button
          icon="save"
          variant="primary"
          :disabled="!hasChanges"
          :loading="weightsUpdateInProgress"
          @click="saveWeightsConfiguration"
        >
          Save
        </Button>
      </div>

      <div
        v-if="weightsLoading"
        class="weights-configuration__content loading"
      >
        <Spinner />
      </div>

      <div
        v-else-if="selectedCompetitionId"
        class="weights-configuration__content"
      >
        <WeightsEdit
          :bookmakers="preMatchBookmakers"
          :title="'PREGAME'"
          :weights-update-in-progress="weightsUpdateInProgress"
          @update:bookmaker="updatePrematchBookmaker"
        />
        <WeightsEdit
          :bookmakers="inPlayBookmakers"
          :title="'IN GAME'"
          :weights-update-in-progress="weightsUpdateInProgress"
          @update:bookmaker="updateInPlayBookmaker"
        />
      </div>

      <div
        v-else-if="!selectedCompetitionId"
        class="empty-state-wrapper"
      >
        <EmptyState
          class="empty-state"
          :message="'Please select competition'"
          :subtitle="'List of available bookmakers will appear after you selected competition on the left side menu'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref, computed, onMounted, watch,
} from 'vue';
import { useStore } from 'vuex';
import { findIndex, find, some } from 'lodash';
import { useRoute } from 'vue-router';
import * as api from '@/services/api';
import Heading from '@/components/common/Heading';
import WeightsSidebar from './WeightsSidebar';
import Spinner from '@/components/common/Spinner';
import Button from '@/components/common/Button';
import EmptyState from '@/components/common/EmptyState';
import WeightsEdit from './WeightsEdit';

const bookmakers = [
  { key: 'BWIN', name: 'Bwin' },
  { key: 'DRAFTKINGS', name: 'Draft Kings' },
  { key: 'FANDUEL', name: 'Fan Duel' },
  { key: 'BETMGM_COLORADO', name: 'BetMGM' },
  { key: 'BET_365', name: 'bet365' },
  { key: 'PINNACLE', name: 'Pinnacle' },
  { key: 'UNIBET', name: 'Unibet' },
  { key: 'BET_188', name: '188Bet' },
  { key: 'BETFIRST', name: 'betFIRST' },
  { key: 'BETANO', name: 'Betano' },
  { key: 'BETSSON', name: 'Betsson' },
  { key: 'ESPN', name: 'ESPN' },
  { key: 'FONBET', name: 'Fonbet' },
  { key: 'MARATHON_BET', name: 'Marathonbet' },
  { key: 'PADDY_POWER', name: 'Paddy Power' },
  { key: 'SBO', name: 'SBOBet' },
  { key: 'SKY_BET', name: 'Sky Bet' },
  { key: 'TIPICO', name: 'Tipico' },
  { key: 'ONE_XBET', name: '1XBet' },
];

export default {
  components: {
    Heading,
    WeightsSidebar,
    Spinner,
    Button,
    EmptyState,
    WeightsEdit,
  },
  setup() {
    const weightsLoading = ref(false);
    const weightsUpdateInProgress = ref(false);
    const mapBookmakers = (bookmakersList, data, type) => {
      const bookmakerData = data[type] || {};

      return bookmakersList.map((bookmaker) => ({
        key: bookmaker.key,
        name: bookmaker.name,
        active: bookmaker.key in bookmakerData,
        weight: bookmaker.key in bookmakerData ? bookmakerData[bookmaker.key] : 0.1,
      }));
    };

    const route = useRoute();
    const store = useStore();
    const selectedCompetitionId = computed(() => route.query.competition);
    const inPlayBookmakers = ref([]);
    const preMatchBookmakers = ref([]);
    const initialState = ref(null);
    const inPlayWeightsSum = computed(() => inPlayBookmakers.value.reduce((acc, bookmaker) => {
      if (!bookmaker.active) return acc;
      return Number(Number(acc + bookmaker.weight).toFixed(3));
    }, 0));
    const preMatchWeightsSum = computed(() => preMatchBookmakers.value.reduce((acc, bookmaker) => {
      if (!bookmaker.active) return acc;
      return Number(Number(acc + bookmaker.weight).toFixed(3));
    }, 0));
    const hasError = computed(() => inPlayWeightsSum.value > 1 || preMatchWeightsSum.value > 1 || some(inPlayBookmakers.value, (bookmaker) => bookmaker.active && bookmaker.weight <= 0) || some(preMatchBookmakers.value, (bookmaker) => bookmaker.active && bookmaker.weight <= 0));
    const title = computed(() => {
      const competition = find(store.getters.sidebarCompetitions, (comp) => comp.competitionId === selectedCompetitionId.value);
      return competition ? competition.competitionName : 'Param Finder Configuration - Weights';
    });
    const hasChanges = computed(() => {
      if (!initialState.value || hasError.value) return false;
      const currentState = {
        inPlay: JSON.stringify(inPlayBookmakers.value),
        preMatch: JSON.stringify(preMatchBookmakers.value),
      };
      return currentState.inPlay !== initialState.value.inPlay
                          || currentState.preMatch !== initialState.value.preMatch;
    });

    const setInitialState = () => {
      initialState.value = {
        inPlay: JSON.stringify(inPlayBookmakers.value),
        preMatch: JSON.stringify(preMatchBookmakers.value),
      };
    };

    const loadWeightsConfig = () => {
      weightsLoading.value = true;

      api.getBookieWeights(selectedCompetitionId.value)
        .then((response) => {
          inPlayBookmakers.value = mapBookmakers(bookmakers, response.data, 'inPlay');
          preMatchBookmakers.value = mapBookmakers(bookmakers, response.data, 'preMatch');
          setInitialState();
        })
        .finally(() => {
          weightsLoading.value = false;
        });
    };

    const updateInPlayBookmaker = (updatedBookmaker) => {
      const index = findIndex(inPlayBookmakers.value,
        (bookmaker) => bookmaker.key === updatedBookmaker.key);
      if (index !== -1) {
        inPlayBookmakers.value[index] = updatedBookmaker;
      }
    };
    const updatePrematchBookmaker = (updatedBookmaker) => {
      const index = findIndex(preMatchBookmakers.value,
        (bookmaker) => bookmaker.key === updatedBookmaker.key);
      if (index !== -1) {
        preMatchBookmakers.value[index] = updatedBookmaker;
      }
    };
    const saveWeightsConfiguration = async () => {
      const data = {
        competitionId: selectedCompetitionId.value,
        inPlay: inPlayBookmakers.value.reduce((acc, bookmaker) => {
          if (bookmaker.active) {
            acc[bookmaker.key] = bookmaker.weight;
          }
          return acc;
        }, {}),
        preMatch: preMatchBookmakers.value.reduce((acc, bookmaker) => {
          if (bookmaker.active) {
            acc[bookmaker.key] = bookmaker.weight;
          }
          return acc;
        }, {}),
      };
      try {
        weightsUpdateInProgress.value = true;
        await api.saveBookieWeights(data);
        store.dispatch('addNotification', {
          message: 'Weights configuration succesfully saved',
          type: 'success',
          duration: 5000,
        });
        weightsUpdateInProgress.value = false;
        setInitialState();
      } catch (error) {
        weightsUpdateInProgress.value = false;
        store.dispatch('addNotification', {
          message: 'Weights configuration save failed',
          type: 'error',
          duration: 5000,
        });
      }
    };
    onMounted(() => {
      if (!route.query.competition) return;
      loadWeightsConfig();
    });
    watch(() => route.query.competition, () => {
      if (!route.query.competition) return;
      loadWeightsConfig();
    });

    return {
      weightsLoading,
      title,
      hasChanges,
      selectedCompetitionId,
      bookmakers,
      inPlayBookmakers,
      preMatchBookmakers,
      weightsUpdateInProgress,
      updateInPlayBookmaker,
      updatePrematchBookmaker,
      saveWeightsConfiguration,
    };
  },
};
</script>

<style lang="scss">
.weights-configuration {
  .weights-configuration__main {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    padding-top: 8px;
  }

  .weights-configuration__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    padding: 32px;

    .weights-configuration__text {
      display: flex;
      flex-direction: column;
      position: relative;

      .icon {
        position: absolute;
        left: -25px;
        top: 5px;
        width: 18px;
        height: 18px;
        cursor: pointer;
        svg path {
          stroke: $black;
        }
      }
      p {
        font-size: 12px;
        color: $gray700;
      }
    }
    .button.button--primary {
      stroke: unset;
    }

    .icon svg path{
      fill: #fff;
    }
  }

  .weights-configuration__content {
    height: calc(100% - 120px);
    padding: 0 32px;
    overflow-y: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
    &.loading {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 24px 24px;
      height: calc(100% - 100px);
      overflow-y: auto;
    }
  }

  .empty-state-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    width: 100%;

    .empty-state {
      width: 400px;
    }
  }
}
</style>
